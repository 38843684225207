export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    fontWeight: '500',
    padding: '1.25rem',
    fontSize: '1rem',
    textTransform: 'uppercase',
    borderRadius: '0px',
    letterSpacing: '2px',

    ':hover': {
      backgroundColor: 'transparent',
      color: 'primary'
    }
  },
  secondary: {
    backgroundColor: 'black',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    fontWeight: '500',
    padding: '1rem',
    fontSize: '1rem',
    textTransform: 'uppercase',
    borderRadius: '0px',

    ':hover': {
      opacity: '0.6'
    }
  }
}
